import '@drdropin-tech/theseus/lib/tailwind.css';
import '@styles/tailwind.css';

import CookieManager from '@components/CookieManager';
import Favicon from '@components/Favicon';
import Layout from '@layouts/Layout';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Analytics } from '@vercel/analytics/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import { NextAdapter } from 'next-query-params';
import { useState } from 'react';
import { QueryParamProvider } from 'use-query-params';

const BookingApp = ({ Component, pageProps, router }: AppProps) => {
  const pProps = pageProps;
  const [queryClient] = useState(() => new QueryClient());
  return (
    <>
      <Head>
        <title>Book en time hos Dr.Dropin</title>
        <meta name="description" content="Helsetjenester" />
        <meta property="og:title" content="Book en time hos Dr.Dropin" />
        <meta
          name="viewport"
          content="width=device-width, user-scalable=no, viewport-fit=cover"
        />

        {/* TODO: Should implement more open graph meta tags */}

        <Favicon />
      </Head>
      <QueryParamProvider adapter={NextAdapter}>
        <Layout pathname={router.asPath}>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pProps.dehydratedState}>
              <CookieManager />
              <Component {...pageProps} />
              <ReactQueryDevtools
                initialIsOpen={false}
                position="bottom-right"
              />
            </Hydrate>
          </QueryClientProvider>
        </Layout>
      </QueryParamProvider>
      <Analytics />
    </>
  );
};

export default appWithTranslation(BookingApp);
