import { getVertical } from '@booking/shared';
import { FC, ReactNode } from 'react';

type Props = {
  pathname: string;
  children: ReactNode | ReactNode[];
};

const Layout: FC<Props> = ({ children, pathname }) => {
  const vertical = getVertical(pathname);
  return (
    <div
      data-theme={vertical === 'mental-health' ? 'psychology' : vertical}
      className="bg-primary flex min-h-screen w-full flex-col items-center transition-colors"
    >
      <main className="flex w-full flex-1 justify-center">{children}</main>
    </div>
  );
};

export default Layout;
