import { getCookieConsent, useCookieStore } from '@booking/shared';
import * as Sentry from '@sentry/nextjs';
import Cookies from 'js-cookie';
import { posthog } from 'posthog-js';
import React from 'react';
import TagManager from 'react-gtm-module';

import CookieBot from './CookieBot';

// Identifier for group id for Cookiebot-script
const domainGroupId = '22daabf7-a9ed-4662-bd0a-2879810d1971';

const CookieManager = () => {
  // CookieBot sets a cookie when patient interacts with it. Check if it exists.
  const [patientAlreadyAnswered, setPatientAlreadyAnswered] = React.useState(
    !!getCookieConsent(document?.cookie),
  );

  const { setPosthogId, setPosthogEnabled } = useCookieStore((state) => ({
    setPosthogId: state.setPosthogId,
    setPosthogEnabled: state.setPosthogEnabled,
  }));

  function posthogInit() {
    posthog.init(`${process.env.NEXT_PUBLIC_POSTHOG_API_KEY}`, {
      api_host: 'https://eu.posthog.com',
      autocapture: false,
      loaded: () => {
        Cookies.set('posthogCapture', 'true');
        setPosthogId(posthog.get_distinct_id());
        Sentry.setUser({ id: posthog.get_distinct_id() });
        setPosthogEnabled(true);
        posthog.capture('session_start_v2');
      },
    });
  }

  if (
    patientAlreadyAnswered &&
    !window.google_tag_manager &&
    process.env.NEXT_PUBLIC_ENV === 'production'
  ) {
    const cookieConsent = getCookieConsent(document.cookie);

    // If patient has already answered, we can check if they have accepted or declined
    if (cookieConsent?.marketing) {
      TagManager.initialize({
        gtmId: `${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`,
      });
    }

    if (cookieConsent?.statistics) {
      posthogInit();
    }
  }

  const handleAccept = () => {
    const cookieConsent = getCookieConsent(document.cookie);
    if (cookieConsent) {
      // Cookies.set('next-gdpr-google-analytics', cookieConsent.statistics)
      Cookies.set(
        'next-gdpr-google-tagmanager',
        String(cookieConsent.marketing),
      );
      setPatientAlreadyAnswered(true);
      // Initialize services based on consent given in CookieBot. After user has accepted,
      // the gatsby-plugin-gdpr-cookies will check the cookies set above instead and initialize
      // on page load.
      if (
        cookieConsent.marketing &&
        process.env.NEXT_PUBLIC_ENV === 'production'
      ) {
        TagManager.initialize({
          gtmId: `${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`,
        });
      }

      if (cookieConsent.statistics) {
        posthogInit();
      }
      // initializeAndTrack(location)
    }
  };

  const handleDecline = () => {
    // Cookies.set('next-gdpr-google-analytics', 'false')
    Cookies.set('posthogCapture', 'false');
    Cookies.set('next-gdpr-google-tagmanager', 'false');
    setPatientAlreadyAnswered(true);
    setPosthogEnabled(false);
    // In case user denies cookies, zendesk will not open properly. So hide it.
    // window.zE('webWidget', 'hide')
  };

  return (
    <CookieBot
      patientAlreadyAnswered={patientAlreadyAnswered}
      domainGroupId={domainGroupId}
      language={navigator?.language?.indexOf('en') > -1 ? 'en' : 'no'}
      onAccept={handleAccept}
      onDecline={handleDecline}
    />
  );
};

// Cookies are not a thing server-side, so when the component is rendered server-side
// during Gatsby build, don't render the component at all.
const CookieManagerWrapper = () =>
  typeof document !== 'undefined' ? <CookieManager /> : null;

export default CookieManagerWrapper;
