import PropTypes from 'prop-types';

type CookieBotProps = {
  domainGroupId: string;
  language?: string;
  onAccept?: () => void;
  onDecline?: () => void;
  patientAlreadyAnswered?: boolean;
};
function CookieBot({
  domainGroupId,
  language,
  onAccept,
  onDecline,
  patientAlreadyAnswered,
}: CookieBotProps) {
  if (patientAlreadyAnswered || typeof document === 'undefined') return null;
  const script = document.createElement('script');
  script.setAttribute('id', 'CookieBot');
  script.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
  script.setAttribute('data-cbid', domainGroupId);
  // script.setAttribute('data-blockingmode', 'auto');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('data-culture', language || navigator.language || 'en');

  if (onAccept) window.addEventListener('CookiebotOnAccept', onAccept);
  if (onDecline) window.addEventListener('CookiebotOnDecline', onDecline);

  const head = document.querySelector('html > head');
  head?.insertBefore(script, head.firstChild);
  return null;
}

CookieBot.propTypes = {
  domainGroupId: PropTypes.string.isRequired,
  language: PropTypes.string,
  onAccept: PropTypes.func,
  onDecline: PropTypes.func,
  patientAlreadyAnswered: PropTypes.bool,
};

CookieBot.defaultProps = {
  language: 'en',
  onAccept: () => {},
  onDecline: () => {},
  patientAlreadyAnswered: false,
};

export default CookieBot;
